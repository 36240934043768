import { useNavigate } from "react-router-dom";
import { useState } from "react";

const JAMBResult = () => {
  const [result, setResult] = useState(
    JSON.parse(localStorage.getItem("result")),
  );
  const [timer, setTimer] = useState(localStorage.getItem("takenTime"));

  const navigate = useNavigate();
  window.location.hash = "no-back-button";
  window.onhashchange = function() {
    window.location.hash = "no-back-button";
  };

  const checkCorrectionButton = () => {
    navigate("/exam/jamb-check-corrections");
  };

  const homeButton = () => {
    localStorage.removeItem("takenTime");
    localStorage.removeItem("result");
    localStorage.removeItem("testPaperID");
    localStorage.removeItem("examID");
    navigate("/exam");
  };

  return (
    <>
      <div className="jabm-sec">
        <div className="jabm-container">
          <div className="resulttl">
            <h2>Result</h2>
            <h4>Completed in: {timer}</h4>
          </div>
          <div className="resultWrap">
            <div className="result-mid">
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>Subject</th>
                    <th>Score</th>
                  </tr>
                </thead>
                <tbody>
                  {result?.result?.map((i) => (
                    <tr>
                      <td>{i.sub}</td>
                      <td>{i.answer}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="totalpanel">
              <span>Total Score:</span>
              <strong>{result["total_score"]}</strong>
            </div>
            <div className="btns-panel">
              <button
                className="btn"
                onClick={() => {
                  homeButton();
                }}
              >
                Go Home
              </button>
              {/* <div className="qucomm-btn-wrap"> */}
              <button
                className="btn "
                onClick={() => {
                  checkCorrectionButton();
                }}
              >
                Check Corrections
              </button>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JAMBResult;
