import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  getAuthRequest,
  getRequest,
  getUserData,
  postRequest,
} from "../../services";
import {
  GET_MOTIVATION_QUOTE_DETAILS,
  GET_RECOMMANDATION_EXAMS,
  GET_USER_EXAM_SCORE,
  GET_USER_EXAM_START_OVER,
  PROFILE_API,
  USER_DASHBOARD_REPORT,
  USER_EXAM,
  USEREXAM,
  WEEK_WORKING_HOUR,
} from "../../services/ApiConstant";
import { Button, Modal, Skeleton, message } from "antd";
import { titleCase } from "../../utils";
import { UpdateSponsorInfo } from "../Auth/Sponsor";
import { Head } from "../../Components/seo";

const StartOverModal = ({
  open,
  handleOk,
  handleCancel,
  startOver,
  id,
  isUtme,
  logo,
  examName,
  time,
}) => {
  return (
    <Modal
      open={open}
      centered={true}
      title="Start Over"
      onOk={handleOk}
      onCancel={handleCancel}
      width={300}
      footer={
        <div className="w-100">
          <Button
            className="w-100"
            type="primary"
            key="back"
            onClick={handleCancel}
          >
            No, I don’t want to Start Over
          </Button>
          <Button
            className="w-100 m-0 mt-2"
            key="submit"
            onClick={() => {
              handleOk();
              startOver(id, isUtme, logo, examName, time);
            }}
          >
            Yes, I want to Start Over
          </Button>
        </div>
      }
    >
      <br />
      <p>If you start over, you are going to lose your progress.</p>
      <br />
    </Modal>
  );
};

const Dashboard = ({ step, page, setIsLoggedIn }) => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [userExam, setUserExam] = useState([]);
  const [fullData, setFullData] = useState();
  const [dashboardReport, setDashboardReport] = useState();
  const [examId, setExamId] = useState();
  const [examCompletePercentage, setExamCompletePercentage] = useState();
  const [motivationQuote, setMotivationQuote] = useState([]);
  const [score, setScore] = useState({ score: 0, total_marks: 0 });
  const [showPer, setShowPer] = useState(true);
  const [workingTimeChart, setWorkingTimeChart] = useState([]);
  const [recommendCourse, SetRecommendCourse] = useState([]);
  const [startCourse, setStartCourse] = useState(false);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState();
  const [isUtme, setIsUtme] = useState();
  const [logo, setLogo] = useState();
  const [examName, setExamName] = useState();
  const [time, setTime] = useState();
  const [examYearData, setExamYearData] = useState();
  const [wait, setWait] = useState(false);
  const [openSponsor, setOpenSponsor] = useState(false);

  const days = {
    Sunday: "Sun",
    Monday: "Mon",
    Tuesday: "Tue",
    Wednesday: "Wed",
    Thursday: "Thu",
    Friday: "Fri",
    Saturday: "Sat",
  };

  useEffect(() => {
    // localStorage.removeItem('examName');
    localStorage.removeItem("result");
    localStorage.removeItem("profileImage");
    localStorage.removeItem("AnsScreenOf");
    localStorage.removeItem("exam");
    // localStorage.removeItem('examID');
    localStorage.removeItem("testpaperbunch");
    // localStorage.removeItem('Logo');
    localStorage.removeItem("testPaperID");
    localStorage.removeItem("takenTime");
    localStorage.removeItem("isCalculator");
    // localStorage.removeItem('ExamTime');
    localStorage.removeItem("group");
    localStorage.removeItem("SelectedYear");
    localStorage.removeItem("subjectSelected");
    // navigator.geolocation.getCurrentPosition((position) => {
    //   console.log("current position", position);
    // });
  }, []);

  useEffect(() => {
    let url = window.location.search;
    if (url.length > 0) {
      setWait(true);
      const timer = setTimeout(() => {
        getUserData(USEREXAM).then((res) => {
          setUserExam(res?.data);
          res?.data.length === 0 && setWait(false);
        });
      }, 2000);
    } else {
      setWait(false);
      const timer = setTimeout(() => {
        getUserData(USEREXAM).then((res) => {
          setUserExam(res?.data);
        });
      }, 0);
    }

    getUserData(GET_RECOMMANDATION_EXAMS).then((res) => {
      SetRecommendCourse(res?.data);
    });

    getUserData(GET_USER_EXAM_SCORE)
      .then((res) => {
        setExamYearData(res?.data);
        if (res?.data.length != 0) {
          setScore(res?.data[0]);
        } else {
          setScore({ score: 0, total_marks: 0 });
        }
      })
      .catch(() => {
        setScore({ score: 0, total_marks: 0 });
      });

    getAuthRequest(PROFILE_API, {}).then((res) => {
      setFullData(res.data);
      localStorage.setItem("userData", JSON.stringify(res.data));
    });

    getUserData(USER_DASHBOARD_REPORT).then((res) => {
      setDashboardReport(res?.data);
    });

    getRequest(GET_MOTIVATION_QUOTE_DETAILS).then((res) => {
      setMotivationQuote(res?.data);
    });

    getUserData(WEEK_WORKING_HOUR).then((res) => {
      setWorkingTimeChart(res?.data);
    });
  }, []);

  const [startOverStatus, setStartOverStatus] = useState(false);
  const handleOk = () => {
    setStartOverStatus(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const startOver = (id, isUtme, logo, examName, time) => {
    setOpen(true);
    setId(id);
    setIsUtme(isUtme);
    setLogo(logo);
    setExamName(examName);
    setTime(time);
    getUserData(GET_USER_EXAM_START_OVER + `?exam_id=${id}`);

    localStorage.setItem("ExamTime", time);
    localStorage.setItem("examID", id);
    if (isUtme) {
      localStorage.setItem("Logo", logo);
      localStorage.setItem("examName", examName);
      navigate("/exam/utme-simulation");
    } else {
      switch (id) {
        case 2:
          {
            open && navigate("/exam/jamb-simulation");
          }
          break;
        default:
          navigate("/exam/exam-simulation");
          break;
      }
    }
    setExamId(id);
  };

  const firstTimeStartExam = (
    id,
    isUtme,
    logo,
    examName,
    exam,
    time,
    payment,
  ) => {
    postRequest(USER_EXAM + "?exam_id" + "=" + id).then((res) => {
      localStorage.setItem("ExamTime", time);
      localStorage.setItem("examID", id);
      if (isUtme) {
        localStorage.setItem("Logo", logo);
        localStorage.setItem("examName", examName);
        navigate("/exam/utme-simulation");
      } else {
        switch (exam) {
          case "JAMB":
            navigate("/exam/jamb-simulation");
            break;
          case "IELTS":
            navigate("/exam/ielts-simulation");
            break;

          default:
            navigate("/exam/exam-simulation");
            localStorage.setItem("exam", exam);
            break;
        }
      }
    });
  };

  const startHandler = () => {
    setStartCourse(true);
  };

  useEffect(() => {
    if (open) {
      setOpen(true);
    }
  }, [open]);

  useEffect(() => {
    if (fullData) {
      if (!fullData?.user?.sponsor_name) {
        setOpenSponsor(true);
      } else {
        setOpenSponsor(false);
      }
    }
  }, [fullData]);

  const predictedYear = new Date().getFullYear() + 1;
  const navigateToPredictedQuestion = (examId) => {
    console.log("going to predicted questions", examId);
    navigate("/exam/predicted-questions", { state: { id: examId } });
  };

  return (
    <div className="home">
      <Head title="Dashboard" />
      {contextHolder}
      <StartOverModal
        open={open}
        handleOk={handleOk}
        handleCancel={handleCancel}
        startOver={startOver}
        id={id}
        isUtme={isUtme}
        logo={logo}
        examName={examName}
        time={time}
      />
      <UpdateSponsorInfo
        openSponsor={openSponsor}
        close={() => setOpenSponsor(false)}
      />
      <div className="home-left">
        <div className="welcome-banner">
          <div className="text-container">
            <div className="text-with-hand-icon">
              <h3>
                Welcome Back,{" "}
                {fullData?.full_name != undefined &&
                  titleCase(fullData?.full_name)}
              </h3>
              <span className="icon-container">
                <img src="/images/welcome-hand.svg" alt=""></img>
              </span>
            </div>
            <p>
              Welcome back to ExcelMind, the ultimate home for exam simulation!
              We're thrilled to have you back, and we hope you're ready to take
              your academic performance to new heights.
            </p>
          </div>
        </div>

        <h3 className="list-heading2">Your Registered Exam</h3>

        {userExam?.length > 0 ? (
          <div className="exam-lists">
            {userExam?.length > 0 &&
              userExam?.map((item, index) => {
                return (
                  <div key={index} className="exam-list-col">
                    <div className="exam">
                      <div
                        className="examinfo"
                        onClick={() =>
                          firstTimeStartExam(
                            item?.exam_id,
                            item?.is_utme,
                            item?.icon,
                            item?.exam_full_name,
                            item?.exam,
                            item?.exam_time,
                            item?.payment_status,
                          )
                        }
                      >
                        <div className="image">
                          <img src={item?.icon} />
                        </div>
                        <div className="infotext">
                          <div className="exam-name">
                            {
                              examYearData?.filter((currExamYear) => {
                                // return currExamYear?.
                                return currExamYear?.exam_name == item.exam;
                              })[0]?.year
                            }
                            <p>
                              Year {item.first_year}-{item.last_year}
                            </p>
                            <h2>{item.exam_full_name}</h2>
                          </div>
                        </div>
                      </div>
                      <div className="exambtnpanel">
                        <button
                          className="start-buy buy"
                          onClick={() =>
                            firstTimeStartExam(
                              item?.exam_id,
                              item?.is_utme,
                              item?.icon,
                              item?.exam_full_name,
                              item?.exam,
                              item?.exam_time,
                              item?.payment_status,
                            )
                          }
                        >
                          <img src="../images/start.svg" />
                          <p className="start-buy" onClick={startHandler}>
                            Start{" "}
                          </p>
                        </button>
                        {/* } */}
                      </div>
                    </div>
                  </div>
                );
              })}
            <div className="future_questions">
              <div className="future_questions__heading">
                <h3>Predicted Questions</h3>
                <p>
                  These are predicted interview questions to help you
                  practice.—these are possible outcomes based on trends, but
                  keep in mind they're not 100% guaranteed.
                </p>
              </div>

              <div className="future_questions__box">
                <div className="future_question">
                  <div className="future_question__exam">
                    <div className="future_question__image__box">
                      <img
                        className="future_question__image"
                        src="/images/waec.png"
                        alt="waec"
                      />
                    </div>
                    <div className="future_question__info">
                      <p className="future_question__year">{predictedYear}</p>
                      <h3 className="future_question__title">
                        West African Examination Council
                      </h3>
                    </div>
                  </div>
                  <button
                    className="future_question__start"
                    onClick={() => navigateToPredictedQuestion(1)}
                  >
                    <img src="../images/start_outline.svg" />
                    <span>start</span>
                  </button>
                </div>

                <div className="future_question">
                  <div className="future_question__exam">
                    <div className="future_question__image__box">
                      <img
                        className="future_question__image"
                        src="/images/jamb.png"
                        alt="jamb"
                      />
                    </div>
                    <div className="future_question__info">
                      <p className="future_question__year">{predictedYear}</p>
                      <h3 className="future_question__title">
                        Joint Admissions and Matriculation Board
                      </h3>
                    </div>
                  </div>
                  <button
                    className="future_question__start"
                    onClick={() => navigateToPredictedQuestion(2)}
                  >
                    <img src="../images/start_outline.svg" />
                    <span>start</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : wait ? (
          <div className="exam-list-col">
            <div className="exam">
              <div className="examinfo">
                <div style={{ padding: "16px" }}>
                  {/* <img /> */}
                  <Skeleton.Image active={true} />
                </div>
                <Skeleton paragraph={{ rows: 1 }} active />
              </div>
              <div className="exambtnpanel">
                <button className="start-buy buy">
                  <img src="../images/start.svg" />
                  <p className="start-buy">Start </p>
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="no-exam-find-msg">
            <div className="icon-container">
              <img src="/images/empty-exam.png"></img>
            </div>
            <div className="msg-container">
              <h4>No Registered Exam</h4>
              <p>
                You don't have an active subscription to an exam click the
                register exam button to register for one
              </p>
              <div className="action">
                <button
                  type="button"
                  className="register-btn"
                  onClick={() => navigate("/dashboard/other-exams")}
                >
                  Register Exam
                </button>
              </div>
            </div>
          </div>
        )}

        {/* {recommendCourse.length > 0 && <h3>Other Course Recommendations</h3>} */}

        {/* {recommendCourse.length > 0 && recommendCourse?.map((i, index) => (
          <div className="recommend-exam" key={index}>
            <div className="recommend-exam-wrap">
              <div className="recommend-image">
                <img src={i.icon} />
              </div>
              <div className="recommend-info">
                <div className="r-exam-name">
                  <p>Year {i.first_year}-{i.last_year}</p>
                  <h2>{i.exam_full_name}</h2>
                  <br />
                  <p style={{ color: '#2F327D', fontSize: 13 }}>₦{i.price}</p>
                </div>
              </div>
            </div>
            <div className="recommend-exam-btn-wrap">
              <div className="buy subscribe me-0" onClick={() => { subcribeForExam({ id: i.exam_id, name: i.exam, mainId: i.category_id, isUtme: i.is_utme }) }}>
                <RiShoppingCartLine />
                <p>Subscribe</p>
              </div>
            </div>
          </div>
        ))} */}
      </div>
      <div className="home-right">
        <h1>Overview</h1>
        <div className="average_score">
          <div>
            <img src="../images/average-score.svg" />
          </div>
          <div>
            <p>Average Score</p>{" "}
            <h1>{`${score?.score}/${score?.total_marks}`}</h1>
          </div>
        </div>
        <div className="registered_exam">
          <div>
            <img src="../images/registered-exam.svg" />
          </div>
          <div>
            <p>Registered Exam</p> <h1>{userExam?.length} </h1>
          </div>
        </div>
        <div className="years_completed">
          <div>
            <img src="../images/years-completed.svg" />
          </div>
          <div>
            <p>Year completed</p> <h1>{score?.year ? score?.year : 0}</h1>
          </div>
        </div>
        {workingTimeChart.length != 0 && (
          <div className="bar-chart-box">
            <h3>Learning Hours</h3>
            <div className="bar-chart">
              {workingTimeChart?.map((item, index) => (
                <div className="main-bar" key={index}>
                  <div
                    className="bar"
                    style={{
                      height: `${4.1666667 * (item.end_time > 24 ? 20 : item.end_time)}%`,
                    }}
                  >
                    <div className="hr-chip">
                      <p>
                        <span>{Math.round(item.end_time)}h</span>
                      </p>
                    </div>
                  </div>
                  <p className="bar-name"> {days[item.week_day]} </p>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="motivation">
          <h1>Daily Motivation</h1>
          <p>{motivationQuote.details}</p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
