import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getRequest, postAuthRequest, postRequest } from "../../services";
import {
  EXAM_BODY,
  POST_USER_CATEGORY,
  TRANSACTION,
} from "../../services/ApiConstant";
import { RiShoppingCartLine } from "react-icons/ri";
import { message } from "antd";
import { Modal } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { StarIcon } from "../../assets/icons";

const LevelExam = ({ item }) => {
  console.log(item);
  const [exams, setExams] = useState();
  const [error, setError] = useState();
  const [messageApi, contextHolder] = message.useMessage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    getRequest(EXAM_BODY + `/?category=${item?.id}`).then((res) => {
      setExams(res.data);
    });
  }, []);

  const subcribeForExam = () => {
    setIsModalOpen(true);
  };

  console.log("exams ~>", exams);

  const handleSubscribe = (plan_id) => {
    console.log("subscribing...");
    setIsModalOpen(false);
    postRequest(POST_USER_CATEGORY + `?category_id=${item?.id}`)
      .then(() => {
        payment(item?.id, plan_id);
      })
      .catch((e) => {
        setError(e.response.data.error[0]);
      });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const payment = (id, planId) => {
    postAuthRequest(TRANSACTION, { category_id: id, plan_id: planId })
      .then((res) => {
        res.data.message !== undefined && PaymentInfo();
        window.location.href = res.data.data.authorization_url;
      })
      .catch((error) => {
        console.log("error at payment ~>", error);
      });
  };

  const PaymentInfo = () => {
    // messageApi.info('Copied');
    messageApi.open({
      type: "success",
      content: "Subscribed to plan",
      onClose: () => {
        navigate("/dashboard/home");
      },
    });
  };

  console.log("exam item ~>", item);

  return (
    <div className="other-exams">
      {contextHolder}
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        width={400}
        footer={null}
      >
        <h4 className="pricing__title">Choose your preferred subscription</h4>
        <div className="pricing__box pricing__box--modal">
          <div className="pricing__item">
            <div className="pricing__price">
              <h3>
                ₦4,497 <del>₦8,500</del>
              </h3>
              <p>Per Month</p>
            </div>
            <button
              onClick={() => {
                handleSubscribe(item?.plans[0].id);
              }}
              className="pricing__btn"
            >
              Subscribe
            </button>
          </div>
          <div className="pricing__item pricing__item--recommended">
            <div className="pricing__recommendation">
              <StarIcon />
              <span>People’s pick</span>
            </div>
            <div className="pricing__price">
              <h3>
                ₦13,491 <del>₦25,500</del>
              </h3>
              <p>Per Term (Every 3 months)</p>
            </div>
            <button
              className="pricing__btn"
              onClick={() => {
                handleSubscribe(item?.plans[1].id);
              }}
            >
              Subscribe
            </button>
          </div>
        </div>
      </Modal>

      {item === "noData" ? (
        <div className="no-exam-find-msg">
          <div className="icon-container">
            <img src="/images/empty-exam.png"></img>
          </div>
          <div className="msg-container">
            <h4>No Exam Found</h4>
            {/* <p>You don't have an active subscription to an exam click the register exam button to register for one</p> */}
            {/* <div className="action">
              <button type="button" className="register-btn" onClick={() => navigate("/dashboard/other-exams")}>Register Exam</button>
            </div> */}
          </div>
        </div>
      ) : (
        <>
          {!item.coming_soon && (
            <div className="recommend-exam-btn-wrap">
              <div className="">
                {item?.is_subscribed ? (
                  <button
                    disabled
                    style={{ cursor: "default" }}
                    className="subscribe-btn w-auto d-flex direction-row "
                  >
                    <CheckCircleOutlined style={{ color: "green" }} />{" "}
                    &nbsp;&nbsp; Already Subscribed{" "}
                  </button>
                ) : (
                  <button
                    className="subscribe-btn w-auto d-flex direction-row"
                    onClick={() => subcribeForExam()}
                  >
                    <RiShoppingCartLine />
                    &nbsp;&nbsp; <span>Subscribe to {item?.name}</span>
                  </button>
                )}{" "}
              </div>
            </div>
          )}
          <div className="exam-left">
            {exams && (
              <div className="recommend-exam-lists">
                {exams?.map((item) => (
                  <div className="recommend-exam dsdfs">
                    <div className="recommend-exam-wrap">
                      <div className="recommend-image">
                        <img src={item?.icon} />
                      </div>
                      <div className="recommend-info">
                        <div className="r-exam-name">
                          <p>
                            Year {item.first_year}-{item.last_year}
                          </p>
                          <h2>{item.full_name}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default LevelExam;
