import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  GET_QUESTIONS_BY_TESTPAPER_IDS,
  GET_TESTPAPER_QUESTION_ANSWER,
} from "../../../services/ApiConstant";
import { getUserData } from "../../../services";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import Question from "../../../assets/common/Question";
import Options from "../../../assets/common/Option";
import axios from "axios";
import "./explanation.css";

const ExamAnswer = () => {
  const [questions, setQuestions] = useState();

  const navigate = useNavigate();

  let testPaperID = localStorage.getItem("testPaperID");
  let examID = localStorage.getItem("examID");
  let examName = localStorage.getItem("examName");
  let exam = localStorage.getItem("exam");
  const ExamYear = localStorage.getItem("yearSelected");

  useEffect(() => {
    getUserData(
      GET_QUESTIONS_BY_TESTPAPER_IDS + `?testpaper_id=${testPaperID}`
    ).then((res) => {
      setQuestions(res.data);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="jabm-sec">
        <div className="jabm-container">
          <div className="exQulistwap">
            {exam === "SAT" ? (
              <div>
                <h1>{examName} Test</h1> <br />{" "}
                <h4> {questions?.length} QUESTIONS </h4> <br />{" "}
              </div>
            ) : (
              <h2>
                {ExamYear} {exam} {examName}
              </h2>
            )}
            {questions !== undefined ? (
              questions?.map((i, index) => (
                <QuestionExplanation
                  key={index}
                  questions={questions}
                  i={i}
                  index={index}
                  examID={examID}
                  testPaperID={testPaperID}
                />
              ))
            ) : (
              <div
                className="skeleton-loading"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  rowGap: "8px",
                  columnGap: "8px",
                }}
              >
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      height: "20px",
                      width: "calc(100% - 10px)",
                      maxWidth: "570px",
                    }}
                  ></div>
                </div>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      height: "20px",
                      width: "calc(100% - 50px)",
                      maxWidth: "232px",
                    }}
                  ></div>
                </div>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      height: "20px",
                      width: "calc(100% - 50px)",
                      maxWidth: "232px",
                    }}
                  ></div>
                </div>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      height: "20px",
                      width: "calc(100% - 50px)",
                      maxWidth: "232px",
                    }}
                  ></div>
                </div>

                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      height: "20px",
                      width: "calc(100% - 50px)",
                      maxWidth: "232px",
                    }}
                  ></div>
                </div>
              </div>
            )}
            <div className="qucomm-btn-wrap">
              <button
                className="btn"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExamAnswer;

const QuestionExplanation = ({ questions, i, index, examID, testPaperID }) => {
  const [explainAnswer, setExplainAnswer] = useState(false);
  const [explanation, setExplanation] = useState(null);
  const [YourAns, setYourAns] = useState();

  useEffect(() => {
    getUserData(
      GET_TESTPAPER_QUESTION_ANSWER +
        `?exam_id=${examID}&testpaper_id=${testPaperID}`
    ).then((res) => {
      setYourAns(res.data);
    });
    // eslint-disable-next-line
  }, [testPaperID, examID]);
  const ansChecking = (ans, question) => {
    let status = { correct_answer: false, your_answer: false };
    YourAns?.map((i) => {
      if (i.question === question) {
        status.correct_answer = i.correct_answer === ans;
        status.your_answer = i.user_answer === ans;
      }
    });
    return status;
  };
  const showAnswerExplanation = async (options) => {
    try {
      setExplainAnswer(true);
      const res = await axios.post(
        "https://ml.excelmind.org/explain-question",
        options
      );
      const data = res.data;
      setExplanation(data.option_explanation[0].explanation);
    } catch (error) {
      setExplainAnswer(false);
    } finally {
      setExplainAnswer(false);
    }
  };

  return (
    <div>
      <span className="expaination-ttl">
        {questions[index - 1]?.question_details.question_explaination !==
          i.question_details.question_explaination &&
          i.question_details.question_explaination}
      </span>

      <span className="note note-bold">
        {i?.question_details.question_note || i.diagram ? index + 1 + ". " : ""}
        {i?.question_details.question_note}
      </span>

      <span className="daigram">
        {i.diagram && (
          <span className="exQucusupload mb-4">
            <label htmlFor="upload">
              <img src={i?.diagram} alt="" />
            </label>
          </span>
        )}
      </span>

      <div className="exQupanel">
        <div className="qutext">
          <h3>
            <Question
              question={i.question_details.question_text}
              no={
                i?.question_details.question_note || i.diagram ? "" : index + 1
              }
            />
          </h3>
        </div>
        <div className="quoplist">
          <ul className="cusradiolist">
            {i.answer?.map(
              (j, index) =>
                j.ans !== null && (
                  <li key={index}>
                    <input
                      type="radio"
                      id={j.ans}
                      name={i?.question}
                      defaultValue={j.ans}
                      defaultChecked={
                        ansChecking(j.ans, i?.question).your_answer
                      }
                    />
                    <label htmlFor={j.ans}>
                      <Options option={j?.ans} index={index} />
                    </label>{" "}
                    &nbsp;&nbsp;
                    {!ansChecking(j.ans, i?.question).correct_answer &&
                      ansChecking(j.ans, i?.question).your_answer && (
                        <CloseOutlined />
                      )}
                    {ansChecking(j.ans, i?.question).correct_answer && (
                      <CheckOutlined />
                    )}
                  </li>
                )
            )}
          </ul>

          <div className="explanation">
            <button
              disabled={explainAnswer}
              onClick={() =>
                showAnswerExplanation({
                  question_text: i.question_details.question_text,
                  options: [
                    {
                      tag: YourAns[index].correct_answer,
                      is_correct: true,
                    },
                  ],
                })
              }
              className="explanation__btn"
            >
              Show answer explanation
            </button>
            {explanation ? (
              <p className="explanation__text">{explanation}</p>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
